import iconUpdate from '../../assets/images/icons/icon-edit.svg';
import iconRemove from '../../assets/images/icons/icon-trash.svg';
import iconDuplicate from '../../assets/images/icons/icon-duplicate.svg';
import { useState, useEffect, useContext } from "react";
import { useNavigate, Link } from "react-router-dom";
import { DataGridPro, GridActionsCellItem } from '@mui/x-data-grid-pro';
import InfoIcon from '@mui/icons-material/Info';
import Tooltip from '@mui/material/Tooltip';
import { useTranslation } from 'react-i18next';
import { deleteCampaign, getListCampaign, duplicateCampaign } from '../../store/index.service';
import { Popup } from "../../components/UI/Popup/Popup";
import { ConfirmDelete } from "../../components/UI/Popup/ConfirmDelete";
import { ConfirmPopup } from "../../components/UI/Popup/ConfirmPopup";
import { UpgradePlan } from "../../components/UI/Popup/UpgradePlan";
import { StoreContext } from "../../store/store";

export const Campaign = () => {
    const { workspace } = useContext(StoreContext);
    const [listCampaign, setListCampaign] = useState({loading: true, data: []});
    const [showPopupConfirmDelete, setShowPopupConfirmDelete] = useState({show: false});
    const [showPopupConfirmDuplicate, setShowPopupConfirmDuplicate] = useState({show: false});
    const [showPopupUpgradePlan, setShowPopupUpgradePlan] = useState({show: false});
    const navigate = useNavigate();
    const { t } = useTranslation();

    const columns = [
        { field: 'title', headerName: t('campaign_table_title_title'), flex: 1,},
        { 
            field: 'template',
            valueGetter: (params) => params?.name,
            headerName: t('campaign_table_title_template'), 
            flex: 1.5,
            renderHeader: (params) => (
                <span>
                    {params.colDef.headerName}
                    <Tooltip title={t('campaign_table_title_template_tooltip')}>
                        <InfoIcon style={{ marginLeft: 10, fontSize: 'medium', verticalAlign: 'middle' }} />
                    </Tooltip>
                </span>
            )
        },
        { 
            field: 'groups',
            valueGetter: (params) => params.title,
            headerName: t('campaign_table_title_groups'), 
            flex: 1,
            renderHeader: (params) => (
                <span>
                    {params.colDef.headerName}
                        <Tooltip title={t('campaign_table_title_groups_tooltip')}>
                        <InfoIcon style={{ marginLeft: 10, fontSize: 'medium', verticalAlign: 'middle' }} />
                    </Tooltip>
                </span>
            )
        },
        { 
            field: 'status', 
            headerName: t('campaign_table_title_status'), 
            flex: 1,
            renderHeader: (params) => (
                <span>
                    {params.colDef.headerName}
                        <Tooltip title={t('campaign_table_title_status_tooltip')}>
                        <InfoIcon style={{ marginLeft: 10, fontSize: 'medium', verticalAlign: 'middle' }} />
                    </Tooltip>
                </span>
            ),
            renderCell: (params) => (
                <span>
                    {params.row.status === "À envoyer"
                        ? "Envoyée"
                        : params.row.status === "Brouillon"
                        ? "Brouillon"
                        : params.row.status === "Programmer"
                        ? "Programmer"
                        : params.row.status
                    }
                </span>
            )
        },
        { field: 'createdAt', headerName: t('template_table_title_created'), type: 'dateTime', flex: 1, valueGetter: (params) => { return params ? new Date(params.date) : null;}},
        { field: 'nbSend', headerName: 'Envois', flex: 0.5},
        { field: 'nbDelivered', headerName: 'Délivrés', flex: 0.5},
        { field: 'nbNotView', headerName: 'Non lus', flex: 0.5},
        { field: 'nbView', headerName: 'Vus', flex: 0.5},
        { field: 'nbFail', headerName: 'Echoués', flex: 0.9, 
            renderHeader: (params) => (
                <span>
                    {params.colDef.headerName}
                    <Tooltip title={t('campaign_table_title_template_tooltip')}>
                        <InfoIcon style={{ marginLeft: 10, fontSize: 'medium', verticalAlign: 'middle' }} />
                    </Tooltip>
                </span>
            )
        },
        { field: 'actions', type: "actions", flex: 0.8, getActions: (params) => {
            const actions = [
                workspace?.workspaceRole === 'admin' ? (
                    <GridActionsCellItem
                        icon={<img src={iconDuplicate} alt="icon duplicate" />}
                        onClick={() => setShowPopupConfirmDuplicate({ show: true, id: params.row.id })}
                        label="Dupliquer"
                    />
                ) : null,
                workspace?.workspaceRole === 'admin' ? (
                    <GridActionsCellItem
                        icon={<img src={iconUpdate} alt="icon update" />}
                        onClick={() => navigate(`/campagnes/update/${params.row.id}`)}
                        label="Modifier"
                    />
                ) : null,
                workspace?.workspaceRole === 'admin' ? (
                    <GridActionsCellItem
                        icon={<img src={iconRemove} alt="icon remove" />}
                        onClick={() => setShowPopupConfirmDelete({ show: true, id: params.row.id })}
                        label="Supprimer"
                    />
                ) : null
            ];
            return actions.filter((action) => action !== null);
        }}
        
    ];

    useEffect(() => {
        handleGetData();
    }, []);

    const handleGetData = () => {
        getListCampaign().then((res) => {
            setListCampaign({
                loading: false, 
                data: res ?? []
            });
        });
    }  

    const handleDelete = () => {
        deleteCampaign(showPopupConfirmDelete.id).then(() => {
            handleGetData();
        });
    }

    const handleDuplicate = () => {
        duplicateCampaign(showPopupConfirmDuplicate.id).then(() => {
            handleGetData();
        });
    }

    return (
        <div className="campaign">

            <div className="background-white-title">
                <div className="title">
                    <h1>{t('campaign_title')}</h1>
                    {workspace?.metaTier === null && <p>Vous devez vérifier votre entreprise sur Meta pour envoyer plus de 250 messages</p>}
                </div>
                {workspace?.workspaceRole === 'admin' &&
                    <div className="actions">
                        {workspace?.feature === null || workspace?.feature.nbMaxMessageSend <= workspace.countMessageSendInMonth ? (
                            <Link onClick={() => setShowPopupUpgradePlan({ show: true })} className="btn-add disable-btn">{t('campaign_btn_title')}</Link>
                        ) : (
                            <Link to="/campagnes/create" className="btn-add">{t('campaign_btn_title')}</Link>
                        )}
                    </div>
                }
            </div>

            <DataGridPro 
                autoHeight 
                rows={listCampaign.data} 
                columns={columns} 
                pageSize={20} 
                className="table" 
                loading={listCampaign.loading} 
                pagination
                pageSizeOptions={[20, 50, 100]}
            />

            { showPopupConfirmDelete.show &&
                <Popup closePopup={() => setShowPopupConfirmDelete({ show: false })} customContent={true}>
                    <ConfirmDelete msg={t('campaign_popup_delete')} handleDelete={handleDelete} />
                </Popup>
            }

            { showPopupConfirmDuplicate.show &&
                <Popup closePopup={() => setShowPopupConfirmDuplicate({ show: false })} customContent={true}>
                    <ConfirmPopup msg={t('campaign_popup_duplicate')} handleDelete={handleDuplicate} />
                </Popup>
            }

            { showPopupUpgradePlan.show &&
                <Popup closePopup={() => setShowPopupUpgradePlan({ show: false })} customContent={true}>
                    <UpgradePlan />
                </Popup>
            }

        </div>
    )
}